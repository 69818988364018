import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

import HeroVideo from "../../components/shared/generic/hero-video"
import HeroVideoSrcMp4 from "../../video/Aury_walk_sequence_HP_1088x580_v005.mp4"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import SwipeableMobileStepper from "../../components/shared/mobile-carousel"
import akbcares from "../../images/AkebiaCares-logo.png"
import CalloutIcon from "../../images/icons/icon-callout-hp-connect.png"

// carousel thumbs
import slide1 from "../../images/heroes/characters_01_Aury_HP0.png"
import slide2 from "../../images/heroes/characters_02_LottaPills0.png"
import slide3 from "../../images/heroes/characters_03_CalCium0.png"
import slide4 from "../../images/heroes/characters_04_IM_Chewing0.png"

const IndexPage = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v8.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
            <>Data on File 1, Akebia Therapeutics, Inc.</>,
            <>
                Kidney Disease: Improving Global Outcomes (KDIGO) CKD-MBD Update
                Work Group. KDIGO 2017 clinical practice guideline update for
                the diagnosis, evaluation, prevention, and treatment of chronic
                kidney disease-mineral and bone disorder (CKD-MBD).{" "}
                <em>Kidney Int Suppl</em>. 2017;7(1):1‐59.
                doi:10.1016/j.kisu.2017.04.001
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia"
            />
            <body className="hpp hpp-hcp-home" id="hpp-hcp-home" />
        </Helmet>

        <Seo
            title="Healthcare Professionals (HCPs)"
            description="Official AURYXIA® (ferric citrate) website for US healthcare professionals. Read Important Safety Information and Full Prescribing information on this page."
        />

        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <HeroVideo srcMp4={HeroVideoSrcMp4} />
                </div>
                <div>
                    <div className="row">
                        <div className="cta-column mobile"></div>
                        <div className="cta-column">
                            <h1>
                                Need a different choice for HP in{" "}
                                <span className="nowrap">CKD-DD?</span>{" "}
                                <br className="small-only" />
                                Reach for AURYXIA.
                            </h1>
                        </div>
                        <div className="cta-column desktop"></div>
                    </div>
                    <div>
                        <p className="footnote center">
                            CKD=chronic kidney disease; HP=hyperphosphatemia;
                            DD=dialysis.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section id="strong-and-sustained" className="flood-hp col-8 center">
            <div className="flex-row">
                <div>
                    <h2 className="hpp">
                        Strong and sustained phosphorus reductions for more than
                        a year of treatment<sup>1</sup>
                    </h2>

                    <ul>
                        <li>
                            AURYXIA helped lower serum phosphorus to the normal
                            range<sup>2,3</sup>
                        </li>
                        <li>
                            Patients on AURYXIA had a mean serum phosphorus
                            level of 7.41 mg/dL at baseline and 4.88 mg/dL at
                            Week 56<sup>2</sup>
                        </li>
                    </ul>
                    <div className="flex-row links">
                        <div>
                            <Link
                                to="/hyperphosphatemia/ferric-citrate-efficacy/"
                                className="cta hpp"
                            >
                                <span>EXPLORE EFFICACY</span>
                            </Link>
                        </div>
                        <div>
                            <Link
                                to="/hyperphosphatemia/dosing/"
                                className="cta hpp"
                            >
                                <span>SEE DOSING</span>
                            </Link>
                        </div>
                    </div>

                    <p className="center">
                        <a
                            href="/hyperphosphatemia/ferric-citrate-efficacy/#study-design"
                            className="gradient-link blk boldFont"
                        >
                            <span>SEE TRIAL DESIGN</span>
                        </a>
                    </p>
                </div>
            </div>
        </section>

        <section id="mobile-carousel" className="small-only center">
            <div className="flex-row">
                <div>
                    <SwipeableMobileStepper
                        stepperImgs={[
                            {
                                label: "photo 1",
                                imgPath: slide1,
                            },
                            {
                                label:
                                    "Artistic representations of tablets pose with a bottle of AURYXIA® (ferric citrate).",
                                imgPath: slide2,
                            },
                            {
                                label: "photo 3",
                                imgPath: slide3,
                            },
                            {
                                label: "photo 4",
                                imgPath: slide4,
                            },
                        ]}
                    />
                </div>
            </div>
        </section>

        <div className="single_card">
            <EopCalloutCards
                siteAudience="hpp"
                callOneClass="akebia-cares"
                callOneLink={"https://www.akebiacareshcp.com/"}
                callOneContent={
                    <>
                        <img
                            src={akbcares}
                            alt="AkebiaCares"
                            height="81"
                            alt="Akebia Cares logo"
                        />
                        <h3>
                            Your partner in helping patients access the
                            medication they need
                        </h3>
                        <p>
                            AkebiaCares supports your patients with information
                            and resources that can help people start and stay on
                            AURYXIA.
                        </p>
                        <button className="cta akb">
                            <span>FIND SUPPORT OPTIONS</span>
                        </button>
                    </>
                }
                // callTwoClass="highlight"
                // callTwoLink={"/hyperphosphatemia/sign-up?request-rep=true"}
                // //callTwoLink={"/"}
                // callTwoContent={
                //     <>
                //         <img
                //             src={CalloutIcon}
                //             alt="Artistic representation of a customer service representative speaking on a headset"
                //             width="98"
                //             height="98"
                //         />
                //         <h3>Stay connected with&nbsp;AURYXIA</h3>
                //         <p>
                //             Whether you want to talk to a sales representative or
                //             order samples, we have you covered. Let us know what
                //             you&#x2019;re interested in and we&#x2019;ll be in
                //             touch.
                //         </p>
                //         <button className="cta hpp">
                //             <span>GET STARTED</span>
                //         </button>
                //     </>
                // }
            />
        </div>

        <section id="crosslink-slab" className="ida">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3 className="ida">
                        AURYXIA is also indicated to treat iron deficiency
                        anemia (IDA) in adult patients with CKD not on dialysis
                        <sup>1</sup>
                    </h3>
                    <Link to="/iron-deficiency-anemia" className="cta ida">
                        EXPLORE MORE
                    </Link>
                </div>
            </div>
        </section>
    </HPPLayout>
)

export default IndexPage
